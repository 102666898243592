import React from "react";
import Socialicons from "../components/Socialicons";
import Layout from "../components/Layout";

function Streams(){

  return (
    <Layout>
      <div className="mi-home-area mi-padding-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-12">
              <div className="mi-home-content">
                <h1>
                  <span className="mi-name">Pasando de Desarrollo a Producción</span>
                </h1>
                <p>Hablaremos de Git, Github, su utilidad y aplicación en el mundo laboral. Además de como llevar tu aplicación a internet utilizando PaaS (Platforms as a Services)</p>
               
                <p><b>Puedes ver el stream en los siguientes enlaces:</b></p>
                <Socialicons bordered src="streams" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Streams;
